import React, {useState} from "react";
import {Button, Columns, Form, Heading, Modal, Section} from "react-bulma-components";
import ContactSection from "../Contact/ContactSection";
import axios from "axios";
import {NotificationManager} from "react-notifications";


export default function CreateJobModal({endpoint, open, setOpen, data, setData}){
    const [jobState, setJobState] = useState(data);

    const onChange = e => {
        let newJob = {...jobState, [e.target.name]:e.target.value}
        setJobState(newJob);
    };

    const onContactChange = (e, name, field) => {
        const toUpdate = {
            target: {
                name: name,
                value: e[field]
            }
        };
        onChange(toUpdate);
    };

    const clear = e => {
        setJobState({});
        setData({});
    };

    const onSave = e => {
        let dataToSave = {}
        const conf = {
            url: endpoint+"createjob",
            method: "post",
            data: jobState
        };
        axios(conf).then(res => {
            window.open(`/editjob/${res.data.id}`, "_self");
        }).catch(err => {
            NotificationManager.error("There was an error creating this job");
        })
    }




    return (
        <Modal
            closeOnBlur
            show={open}
            onClose={() => {
              clear();
              setOpen(false);
            }}
        >
            <Modal.Content className={"wider-modal"}>
                <Section style={{background: "white"}}>
                    <Columns>
                        <Columns.Column>
                            <Heading>Create Job</Heading>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <ContactSection
                                title="Workshop"
                                contactUpdate={workshop => onContactChange({workshop}, "workshop", "workshop")}
                                endpoint={endpoint + "contacts"}
                                contact={jobState.workshop || {}}
                                type="SP"
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <ContactSection
                                title="Customer 1"
                                contactUpdate={customer => onContactChange({customer}, "customer", "customer")}
                                endpoint={endpoint + "contacts"}
                                contact={jobState.customer || {}}
                                type="CC"
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <ContactSection
                                title="Customer 2"
                                contactUpdate={customer => onContactChange({customer}, "customer2", "customer")}
                                endpoint={endpoint + "contacts"}
                                contact={jobState.customer2 || {}}
                                type="CC"
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Form.Label>Book In Date</Form.Label>
                            <Form.Input
                                type={"date"}
                                name={"book_in_date"}
                                onChange={onChange}
                                value={jobState.book_in_date}
                            />
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Label>Vehicle</Form.Label>
                            <Form.Input
                                disabled={true}
                                type={"text"}
                                value={jobState.vehicle ? jobState.vehicle.registration : ""}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                fullwidth
                                onClick={onSave}
                            >
                                Create
                            </Button>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                color={"info"}
                                fullwidth
                                onClick={() =>{
                                    clear();
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )



}