import React, {useState} from "react";
import {Button, Form} from "react-bulma-components";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import MovementRatePicker from "./MovementRatePicker";
import useInlineEditButton from "../../hooks/useInlineEditButton";
import axios from "axios";
import FAIcon from "../Icon/FAIcon";
import MovementTypePicker from "../Settings/MovementType/MovementTypePicker";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import useEditableTableTimeCell from "../../hooks/useEditableTableTimeCell";
import DriverPicker from "../Settings/Driver/DriverPicker";


const AsyncTypeahead = asyncContainer(Typeahead);

export default function MovementRow({movement, onDelete, onChange, index, onSave, rateOptions, onRateChange, view,
                                        quoteToMovement, onComplete, movementTypes, movementTypeObjects, endpoint, disabled,
                                    selected, onSelectCheck, isJob, driverOptions, driverObjects}){
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [typeahead, setTypeahead] = useState();
    const getColor = () => {
        if(movement.invoice_number){
            return "table-highlight-green"
        }
        else if(movement.job){
            return "colored"
        }
        else if(movement.movement_type && (movement.movement_type.name === "ONHIRE" || movement.movement_type.name === "OFFHIRE")){
            return "table-highlight-orange"
        }
        // else if(movement.hire) {return "table-highlight-orange"}
        else{
            return "table-highlight-dark-red"
        }
    }
    let color = getColor();

    const getSource = () => {
        if(movement.job){
            return "JOB"
        }
        else{
            return "USER CREATED"
        }
    }

    const onRowClick = () =>{
        if(movement.job){
            window.open("/editjob/"+movement.job.id, "_self");
        }
    }
    const overrideOptions = [
        {name:"No", value: false},
        {name:"Yes", value: true}
    ];

    const onTypeChange = e => {
        const toUpdate = {
            target: {
                name: e.target.name,
                value: movementTypeObjects[e.target.value]
            }
        };
        onChange(toUpdate, index);
    };

    const onCostOverrideChange = e => {
        const toUpdate = {
            target: {
                name: e.target.name,
                value: !movement.cost_override
            }
        };
        onChange(toUpdate, index);
    }

    const onDriverChange = e => {
        const toUpdate = {
            target: {
                name: e.target.name,
                value: driverObjects[e.target.value]
            }
        };
        onChange(toUpdate, index)
    }

    const onVehicleUsedChange = e => {
        const toUpdate = {
            target: {
                name:"vehicle_used",
                value: e[0]
            }
        };
        onChange(toUpdate, index);
    };
    const onCheck = e => {
        e.stopPropagation();
        onSelectCheck(movement);
    };
    const handleVehicleSearch = query => {
        setLoading(true);
        axios
          .get(endpoint + `vehicles?showAll=true&search=${query}`)
          .then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
          });
    };

    const onCustomerChange = e => {
        const toUpdate = {
            target: {
                name:"customer",
                value: e[0]
            }
        };
        onChange(toUpdate, index);
    };

    const handleCustomerSearch = query => {
        setLoading(true);
        axios
          .get(endpoint + `contacts?contact_type=CC&search=${query}`)
          .then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
          });
    };

    let rows = []
    const firstRow = (
        <tr
            key={movement.id} className={color}
        >
            <td>
                {useEditableTableTextCell({
                    propName: "id",
                    value: movement.id,
                    disabled: true
                })}
            </td>
            <td>
                {useEditableTableTextCell({
                    propName: "vehicle",
                    value: movement.vehicle,
                    onChange: (e) => onChange(e, index),
                    editable,
                    setEditable,
                    inlineEdit: false,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td>
                <MovementTypePicker
                    editable={editable}
                    setEditable={setEditable}
                    inlineEdit={!disabled}
                    disabled={!editable || disabled}
                    options={movementTypes ?? {results: []}}
                    onSave={() => {
                        onSave(movement);
                        setEditable(false);
                    }}
                    value={movement.movement_type}
                    onChange={onTypeChange}
                />
            </td>
            <td onClick={e => e.stopPropagation()}>
                <AsyncTypeahead
                    useCache={false}
                    isLoading={loading}
                    id="typeahead"
                    labelKey="registration"
                    minLength={2}
                    name={"vehicle_used"}
                    onSearch={handleVehicleSearch}
                    onChange={onVehicleUsedChange}
                    placeholder={`Search Vehicle`}
                    options={searchData}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    disabled={!editable || disabled}
                    selected={movement.vehicle_used && movement.vehicle_used.id ? [movement.vehicle_used] : []}
                />
            </td>
            <td>
                {useEditableTableDateCell({
                    propName: "delivery_date",
                    value: movement.delivery_date,
                    onChange: (e) => onChange(e, index),
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName: "start_address",
                    value: movement.start_address,
                    onChange: (e) => {
                        e.stopPropagation();
                        onChange(e, index);
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName: "end_address",
                    value: movement.end_address,
                    onChange: (e) => {
                        e.stopPropagation();
                        onChange(e, index);
                    },
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td>
                {useEditableTableTimeCell({
                    propName: "start_time",
                    value: movement.start_time,
                    onChange: (e) => onChange(e, index),
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td>
                {useEditableTableTimeCell({
                    propName: "end_time",
                    value: movement.end_time,
                    onChange: (e) => onChange(e, index),
                    editable,
                    setEditable,
                    inlineEdit: true,
                    disabled: disabled,
                    onSave: () => {
                        onSave(movement);
                        setEditable(false);
                    }
                })}
            </td>
            <td onClick={e => e.stopPropagation()}>
                <AsyncTypeahead
                    useCache={false}
                    isLoading={loading}
                    id="typeahead"
                    labelKey="display_name"
                    minLength={2}
                    name={"customer"}
                    onSearch={handleCustomerSearch}
                    onChange={onCustomerChange}
                    placeholder={`Search Customer`}
                    options={searchData}
                    ref={typeahead => setTypeahead(typeahead)}
                    className="typeahead"
                    disabled={!editable || disabled}
                    selected={movement.customer && movement.customer.id ? [movement.customer] : []}
                />
            </td>
            <td>
                <DriverPicker
                    options={driverOptions ?? {results: []}}
                    inlineEdit={true}
                    blank={true}
                    disabled={!editable || disabled}
                    editable={editable}
                    setEditable={setEditable}
                    onChange={onDriverChange}
                    value={movement.driver}
                    onSave={() => {onSave(movement, index); setEditable(false)}}
                />
            </td>
            {!(movement.movement_status === "QUOTE") && !isJob &&
                <td onClick={e => e.stopPropagation()}>
                    <Form.Field>
                        <Form.Control className="table-checkbox">
                            <Form.Checkbox
                                checked={selected}
                                type="checkbox"
                                onClick={onCheck}
                                readOnly
                            />
                        </Form.Control>
                    </Form.Field>
                </td>
            }
            <td>
                <Button
                    onClick={() => onDelete(movement)}
                    disabled={movement.completed}
                    color={"danger"}
                >
                    X
                </Button>
            </td>
            {!movement.completed && !(movement.movement_status === "QUOTE") &&
                <td>
                    <Button
                        color={"success"}
                        onClick={() => {
                            onComplete(movement);
                        }}
                    >
                        <FAIcon size="small" icon={["fas", "check"]}/>
                    </Button>
                </td>
            }
            {view === "QUOTE" && movement.movement_status === "QUOTE" &&
                <td>
                    <Button
                        onClick={() => quoteToMovement(movement)}
                        color={"success"}
                    >
                        Movement
                    </Button>
                </td>
            }
        </tr>
    );
    rows.push(firstRow);
    const secondRow = (
        <tr
            className={color}
        >
            <th className={color}>Rate</th>
            <th className={color}>Distance (Miles)</th>
            <th className={color}>Cost Override</th>
            <th className={color}>Cost Per Mile</th>
            <th className={color}>Additional Time Cost</th>
            <th className={color}>Total Cost</th>
            <th className={color}>Internal Charge</th>
            <th className={color}>Comments</th>
            <th className={color}>Rate Comments</th>
            <th className={color}>Source</th>
            <th className={color}>Creator</th>
            <th></th>
            <th></th>
        </tr>
    );
    rows = rows.concat(secondRow);
    const thirdRow = (
        <tr
            className={color}
        >
            <td>
                {
                    <MovementRatePicker
                        editable={editable}
                        setEditable={setEditable}
                        movement={movement}
                        options={rateOptions ?? {results: []}}
                        onChange={(e) => onRateChange(e, index)}
                        onSave={() => {
                            onSave(movement);
                            setEditable(false);
                        }}
                        inlineEdit={!disabled}
                        disabled={!editable || disabled}
                        blank={true}
                    />
                }
            </td>
            <td>
                {useEditableTableNumberCell({
                    propName: "miles",
                    value: movement.miles,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(movement);
                        setEditable(false)
                    },
                    disabled: disabled,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                <Form.Field className={"has-addons"}>
                    <Form.Control>
                        <Form.Select
                            onChange={onCostOverrideChange}
                            disabled={!editable || disabled}
                            value={movement.cost_override}
                            name={"cost_override"}
                        >
                            {overrideOptions.map(item => (
                                <option key={item.name} value={item.value}>
                                    {item.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Control>
                    {useInlineEditButton({
                        show: true,
                        onSave: () => {
                            onSave(movement);
                            setEditable(false)
                        },
                        setEditable,
                        editable,
                        disabled: disabled
                    })}
                </Form.Field>
            </td>
            <td>
                {useEditableTableCostCell({
                    value: movement.movement_rate ? movement.movement_rate.cost_per_mile : 0,
                    disabled: true,
                    editable,
                    setEditable,
                    inlineEdit: false,
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    value: movement.movement_rate ? movement.movement_rate.additional_time_cost : 0,
                    disabled: true,
                    editable,
                    setEditable,
                    inlineEdit: false,
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "cost",
                    value: movement.cost,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(movement);
                        setEditable(false)
                    },
                    disabled: disabled,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableCostCell({
                    propName: "internal_charge",
                    value: movement.internal_charge,
                    onChange: (e) => onChange(e, index),
                    onSave: () => {
                        onSave(movement);
                        setEditable(false)
                    },
                    disabled: disabled,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    propName: "comments",
                    value: movement.comments,
                    onChange: (e) => {
                        e.stopPropagation();
                        onChange(e, index);
                    },
                    onSave: () => {
                        onSave(movement);
                        setEditable(false)
                    },
                    disabled: disabled,
                    editable,
                    setEditable,
                    inlineEdit: true
                })}
            </td>
            <td>
                {useEditableTableTextAreaCell({
                    value: movement.movement_rate ? movement.movement_rate.comments : "",
                    disabled: true,
                    editable,
                    setEditable,
                    inlineEdit: false
                })}
            </td>
            <td>
                {
                    useEditableTableTextCell({
                        propName: "Source",
                        value: getSource(),
                        disabled: true
                    })
                }
            </td>
            <td>
                {
                    useEditableTableTextCell({
                        propName: "creator",
                        value: movement.creator ? movement.creator.first_name + " " + movement.creator.last_name : ""
                    })
                }
            </td>
            <td></td>
            {view !== "QUOTE" &&
                <td>
                    <Button
                        color={"warning"}
                        onClick={onRowClick}
                    >
                        Open Source
                    </Button>
                </td>
            }
        </tr>
    )
    rows = rows.concat(thirdRow);
    const forthRow = (
        <tr className={"table-highlight-dark-gray"}>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
    );
    rows = rows.concat(forthRow);
    return rows;
}