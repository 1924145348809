import React from "react";
import {readableDate} from "../../utils";


export default function VehicleInspectionRow({inspection}){
    const getRowColor = () => {
        let expiryDate = new Date(inspection.expiry_date);
        const today = new Date();
        // Set The expiry date to the date that the user should first be warned on
        expiryDate.setDate(expiryDate.getDate() - inspection.warning_days);
        // If today is at or after the warn date
        if(today >= expiryDate){
            return "table-highlight-red";
        }
        else{
            return ""
        }
    }
    return (
        <tr className={getRowColor()}>
            <td data-testid={"inspection-type"}>{inspection.inspection_type}</td>
            <td data-testid={"inspection-workshop"}>{inspection.inspection_workshop ? inspection.inspection_workshop.display_name : ""}</td>
            <td data-testid={"inspection-expiry-date"}>{readableDate(inspection.expiry_date)}</td>
        </tr>
    )
}