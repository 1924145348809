import React, {useRef, useState} from "react";
import axios from "axios";
import VehicleJobSection from "../Job/VehicleJobSection";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Form } from "react-bulma-components";
import shortid from "shortid";
import { Button } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import WorkSection from "./WorkSection";
import ContactSection from "../Contact/ContactSection";
import {
  createSetQuoteAction,
  createSetQuoteFieldAction
} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getAppDefaultsData, getDataFresh, getImagesData, getQuoteData, getWorkTypeData} from "../../state/selectors";
import Validator from "./Validator";
import ReactToPrint from "react-to-print";
import PartSearchModal from "../Job/PartSearchModal";
import {getPartCustomerCost, getPartCustomerEstimatedCost,} from "../../utils";
import useSafeSave from "../../hooks/useSafeSave";
import CreateJobCardModal from "./CreateJobCardModal";
import PrintQuote from "./PrintQuote";
import WorkTotalsSection from "../WorkTotals/WorkTotalsSection";
import NotReadyForInvoiceModal from "./NotReadyForInvoiceModal";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import GenericModal from "../Modal/GenericModal";
import TopSummary from "../Job/TopSummary";
import PrintOptionsModal from "./PrintOptionsModal";
import FinalizeModal from "./FinalizeModal";
import ApprovalModal from "../Job/ApprovalModal";
import ModifyQuoteModal from "./ModifyQuoteModal";
import { Table } from "react-bulma-components";
import Comment from "../Job/Comment";
import WorkSearchModal from "../Job/WorkSearchModal";
import QuoteTypeSelector from "./QuoteTypeSelector";
import PrintPickList from "./PrintPickList";
import DefectJobSection from "../Job/Defects/DefectJobSection";
import PartEnquiryJobSection from "../Job/Part Enquiry/PartEnquiryJobSection";
import ImageModal from "../Job/ImageModal";
import {useImagesData} from "../../hooks/useImagesData";

function QuoteForm(props) {
  const componentRef = useRef();
  const dateFields = ["provisional_start_date"];
  const [lastChange, setLastChange] = useState();
  const printPickListRef = useRef();
  const dispatch = useDispatch();
  let quote = useSelector(getQuoteData);
  let appDefaults = useSelector(getAppDefaultsData);
  let workTypes = useSelector(getWorkTypeData);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  let imageParams = {type: "quote"}
  if(quote && quote.vehicle){
        imageParams.blob_name = quote.vehicle.registration+"-"+quote.id;
        imageParams.id = quote.id
  }
  let imagesLoaded = useImagesData(imageParams, dataFresh);
  let images = useSelector(getImagesData);



  quote.estimated_work_items = quote.estimated_work_items.map(
    (item, index) => {
      return {...item, index};
    }
  );

  const [partsModalState, setPartsModalState] = useState({
    modalOpen: false,
    currentSearch: "",
    searchText: "",
    partIndex: 0,
    workIndex: 0
  });


  const [workModalState, setWorkModalState] = useState({
    modalOpen: false,
    currentSearch: "",
    searchText: "",
    workIndex: 0
  });

  const [createJobCardModalState, setCreateJobModalState] = useState({
    modalOpen: false
  });

  const [notReadyModalOpen, setNotReadyModalOpen] = useState(false);
  const [notReadyItems, setNotReadyItems] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [finalizeModalOpen, setFinalizeModalOpen] = useState(false);
  const [printOptionsModalOpen, setPrintOptionsModalOpen] = useState(false);

  const addWorkItem = () => {
    triggerChange({
      estimated_work_items: quote.estimated_work_items.concat({
        key: shortid.generate(),
        chargeable: !!quote.customer2,
        estimated_labour_cost: 0,
        estimated_customer_labour_cost: 0,
        estimated_labour_hours: 0,
        customer_labour_cost: 0,
        labour_cost: 0,
        labour_hours: 0,
        parts: [],
        quote: quote.id || undefined
      })
    });
  };

  const addComment = e => {
    e.preventDefault();
    triggerChange({
      comments_list: quote.comments_list.concat({
        key: shortid.generate(),
        text: "",
        quote: quote.id || undefined
      })
    })
  };


  const finalize = () => {
    const endpoint = props.endpoint + "quotes/finalize/" + quote.id;
    const conf = {
      method: "post",
      url: endpoint
    };
    axios(conf).then(response =>
      dispatch(createSetQuoteAction(response.data))
    );
  };

  const handleFinalize = e => {
    safeSave()
      .then(finalize)
      .catch(reason => {
        NotificationManager.error("Unable to finalize Quote");
      });
  };

  const approveForInvoice = () => {
    let fails = quote.estimated_work_items.filter(item => {
      return !checkWorkItemIsInvoiceReady(item);
    });

    if (fails.length > 0) {
      setNotReadyItems(fails);
      setNotReadyModalOpen(true);
    } else {
      const endpoint = props.endpoint + "quotes/approve/" + quote.id;
      const conf = {
        method: "post",
        url: endpoint
      };
      axios(conf).then(response =>
        dispatch(createSetQuoteAction(response.data))
      );
    }
  };

  const handleApproveForInvoice = () => {
    safeSave()
      .then(approveForInvoice)
      .catch(reason => {
        console.log(reason)
        NotificationManager.error("Unable to approve Quote");
      });
  };

  const checkWorkItemIsInvoiceReady = workItem => {

    if(!workItem.work_type || !workItem.job || !workItem.job_approved){
      return false;
    }
    if(workItem.work_type && workItem.work_type.free_of_charge && workItem.work_type.approval_needed && !workItem.approval_received){
      return false;
    }
    if(!workItem.work_type.free_of_charge && !workItem.job_workshop_invoice_number){
      return false;
    }

    for (let i = 0; i < workItem.parts.length; i++) {
      if (
        !workItem.parts[i].part_invoice_number &&
        !workItem.parts[i].from_stock &&
        !workItem.parts[i].backorder &&
        !workItem.parts[i].backorder_expected_date
      ) {
        return false;
      }
    }
    return true;
  };

  const checkNotUpdated = () => {
    if (quote.id) {
      return axios
        .get(props.endpoint + "quotes/" + quote.id)
        .then(
          result =>
            (new Date(result.data.last_saved).getTime() -
              new Date(quote.last_saved).getTime()) /
            1000 <
            1
        );
    }
    return new Promise((res, rej) => res(true));
  };

  const searchSelectPart = (part, partIndex, workIndex) => {
    let newWorkItem = quote.estimated_work_items[workIndex];
    let customer = newWorkItem.chargeable
      ? quote.customer2
      : quote.customer;

    let newPart = {
      name: part.name,
      part_number: part.part_number,
      supplier: part.supplier,
      cost: part.cost,
      estimated_cost: part.cost,
      estimated_customer_cost: getPartCustomerCost(part, customer, appDefaults),
      customer_cost: getPartCustomerCost(part, customer, appDefaults),
      created_from_lookup: part.id
    };

    newWorkItem.parts[partIndex] = {
      ...newWorkItem.parts[partIndex],
      ...newPart
    };

    updateWork(newWorkItem, workIndex);
    setPartsModalState({...partsModalState, modalOpen: false});
  };

  const partLookupToParts = (lookup) => {
    let part = {
      part_number: lookup.part_number,
      estimated_cost: lookup.cost,
      supplier: lookup.supplier,
      name: lookup.name,
    }

    part.estimated_customer_cost = getPartCustomerEstimatedCost(part, quote.customer2 ? quote.customer2 : quote.customer, appDefaults);
    part.customer_cost = part.estimated_customer_cost;
    part.cost = part.estimated_cost;

    return part;
  }

  const searchSelectWork = (work) => {
    let parts = [];
    work.part_work_lookup.map(part => {
      parts.push(partLookupToParts(part))
    });

    let newWorkItem = {
      key: shortid.generate(),
      description: work.description,
      chargeable: !!quote.customer2,
      labour_cost: 0,
      estimated_labour_cost: work.labour_cost,
      customer_labour_cost: 0,
      estimated_customer_labour_cost: work.customer_labour_cost,
      labour_hours: 0,
      estimated_labour_hours: work.labour_hours,
      parts: parts,
      cost_override: true,
      quote: quote.id || undefined
    };
    triggerChange({
      estimated_work_items: quote.estimated_work_items.concat(newWorkItem)
    });
    setWorkModalState({...workModalState, modalOpen: false});
  };

  const onSearchPartClick = (part, partIndex, workIndex) => {
    const searchText = [
      quote.vehicle ? quote.vehicle.make ? quote.vehicle.make.name : "" : "",
      quote.vehicle ? quote.vehicle.model ? quote.vehicle.model.name : "" : "",
      quote.vehicle.type ? quote.vehicle.type.name : "",
      part.name
    ].join(" ");

    setPartsModalState({
      modalOpen: true,
      currentSearch: searchText,
      workIndex,
      partIndex,
      searchText
    });
  };
  const triggerChange = data => {
    makeDirty();
    setLastChange(new Date());
    dispatch(createSetQuoteFieldAction(data));
  };

  const updateWork = (work, index) => {
    let newWorkItems = [...quote.estimated_work_items];
    newWorkItems.splice(index, 1, work);
    triggerChange({estimated_work_items: newWorkItems});
  };

  const removeWorkItem = (index, work) => {
    if (!work.work) {
      let newWorkItems = [...quote.estimated_work_items];
      newWorkItems.splice(index, 1);
      triggerChange({estimated_work_items: newWorkItems});
    } else {
      NotificationManager.error(
        "This item is linked to a job card. You must delete the work item on the job card first!"
      );
    }
  };

  const updateComment = (comment, index) => {
    let newComments = [...quote.comments_list];
    newComments.splice(index, 1, comment);
    triggerChange({comments_list: newComments});
  };

  const removeComment = index => {
    let newComments = [...quote.comments_list];
    newComments.splice(index, 1);
    triggerChange({comments_list: newComments});
  };


  const handleChange = e => {
    const {name, value} = e.target;
    triggerChange({[name]: value});
  };

  const handleUpdateHireCustomer = e => {
    triggerChange({customer2: e});
    if (!e) {
      let newWorkItems = quote.estimated_work_items.map(item => {
        return {...item, chargeable: false};
      });
      triggerChange({estimated_work_items: newWorkItems});
    }
  };

  const fixEmptyDates = quote => {
    dateFields.forEach(function (date) {
      if (quote[date] === "") {
        quote[date] = null;
      }
    });
    let work_items = quote.estimated_work_items;
    quote.estimated_work_items = work_items.map(work => {
      let parts = work.parts;
      work.parts = parts.map(part => {
        if (part.date_ordered === "") {
          part.date_ordered = null;
        }
        if (part.backorder_expected_date === "") {
          part.backorder_expected_date = null;
        }
        return part;
      });
      return work;
    });
    return quote;
  };

  const handleSave = e => {
    e.preventDefault();
    safeSave();
  };

  const save = () => {
    let quoteToSave = fixEmptyDates(quote);
    const endpoint = quoteToSave.id
      ? props.endpoint + "quotes/" + quoteToSave.id
      : props.endpoint + "createquote";
    const method = quoteToSave.id ? "put" : "post";

    const conf = {
      method: method,
      data: quoteToSave,
      url: endpoint
    };
    return axios(conf).then(resp => {
      dispatch(createSetQuoteAction(resp.data));
      if (!quoteToSave.id) {
        props.history.push("/editquote/" + resp.data.id);
      }
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    save,
    lastChange,
    () => Validator(quote),
    checkNotUpdated
  );

  const handleDelete = e => {
    e.preventDefault();
    const endpoint = props.endpoint + "quotes/" + quote.id;
    const conf = {method: "delete", url: endpoint};
    axios(conf).then(response => props.history.push("/quotes"));
  };

  if (props.loaded) {
     const departmentObjects = props.departments.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {});
     const workTypeObjects = workTypes.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});

     if(!quote.department){
       triggerChange({department: Object.values(departmentObjects)[0]})
     }
    const onDepartmentsChange = (e) => {
      const data = departmentObjects[e.target.value];
      triggerChange({department: data});
    };
    const onQuoteTypeChange = (e) =>{
      triggerChange({quote_type: e.target.value})
    }
    document.title = "Quote - " + (quote.vehicle && quote.vehicle.registration ? quote.vehicle.registration : "New");

    return (
      <div>
        <TopSummary
          quote={true}
          job={quote}
          customer_label={
            quote.customer ? quote.customer.display_name : "Customer"
          }
          customer2_label={
            quote.customer2
              ? quote.customer2.display_name
              : "Customer 2"
          }
          onSave={handleSave}
          itemType="Quote"
          handleChange={handleChange}
        ></TopSummary>
        <VehicleJobSection
          handleVehicleChange={vehicle => triggerChange({vehicle})}
          customerVehicle={quote.customer_vehicle}
          vehicleUpdate={vehicle => triggerChange({vehicle})}
          endpoint={props.endpoint}
          vehicle={quote.vehicle ? quote.vehicle : {}}
          history={props.history}
          type={"Quote"}
          disabled={quote.id}
        />
        <Columns>
          <Columns.Column>
            <ContactSection
              title="Customer"
              contactUpdate={customer => triggerChange({customer})}
              endpoint={props.endpoint + "contacts"}
              contact={quote.customer || {}}
              disabled={quote.customer_invoice_number}
              type="CC"
              displayWorkshopInstructions
            />
          </Columns.Column>
          <Columns.Column>
            <ContactSection
              title="Customer 2"
              contactUpdate={handleUpdateHireCustomer}
              endpoint={props.endpoint + "contacts"}
              contact={quote.customer2 || {}}
              type="CC"
              disabled={quote.customer2_invoice_number}
              displayWorkshopInstructions
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <DefectJobSection
              disabled={true}
              job={quote}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <PartEnquiryJobSection
              disabled={true}
              job={quote}
            />
          </Columns.Column>
        </Columns>
        <Box>
          <h1 className="title">Quote Info</h1>
          {images.length > 0 &&
            <Columns>
              <Columns.Column>
                <p className={"bold"}>This Quote Has Attached Files</p>
              </Columns.Column>
            </Columns>
          }
          <form>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Department</Form.Label>
                  <Form.Control>
                    <Form.Select name='department' value={quote.department ? quote.department.id : 0} onChange={onDepartmentsChange}>
                      {props.departments.results.map(item => (
                        <option key={item.name} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <QuoteTypeSelector
                  selectedtype={quote.quote_type}
                  onChange={onQuoteTypeChange}
                />
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Quote Description</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="description"
                      onChange={handleChange}
                      value={quote.description}
                      required
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Provisional Start Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="provisional_start_date"
                      onChange={handleChange}
                      value={quote.provisional_start_date || ""}
                      required
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Quote Created Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="date_created"
                      value={quote.date_created || ""}
                      disabled
                      readOnly
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Customer 1 Invoice Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="customer_invoice_number"
                      onChange={handleChange}
                      value={quote.customer_invoice_number}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Customer 2 Invoice Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="customer2_invoice_number"
                      onChange={handleChange}
                      value={quote.customer2_invoice_number}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Table size={"fullwidth"}>
                  <thead>
                  <th>Comment</th>
                  <th>Date Created</th>
                  <th>Creator</th>
                  <th>Remove</th>
                  </thead>
                  {quote.comments_list.map((value, index) => (
                    <Comment
                      key={value.id || value.key}
                      index={index}
                      comment={value}
                      updateComments={updateComment}
                      removeComment={removeComment}
                    />
                  ))}
                </Table>
                <Columns>
                  <Columns.Column>
                    <Button
                      tabIndex={-1}
                      type="button"
                      color="info"
                      onClick={addComment}
                    >
                      Add Comment
                    </Button>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
          </form>
        </Box>
        <Box>
          <h1 className="title">Work</h1>
            <Box>
              <Columns>
                <Columns.Column>
                  {quote.estimated_work_items
                      .filter(work => !work.added_after_quote)
                      .map(value => (
                          <WorkSection
                              quote={quote.id}
                              key={value.id || value.key}
                              customer={quote.customer}
                              workTypes={workTypes}
                              workTypeObjects={workTypeObjects}
                              hireCustomer={quote.customer2}
                              vehicle={quote.vehicle}
                              index={value.index}
                              workItem={value}
                              update_work={updateWork}
                              remove_work_item={removeWorkItem}
                              onSearchPartClick={onSearchPartClick}
                              appDefaults={appDefaults}
                              additional={false}
                          />
                      ))}
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <Button id="add-workitem" color="info" onClick={addWorkItem}>
                    Add Work Item
                  </Button>
                  <Button
                      color="info"
                      style={{marginLeft: '10px'}}
                      onClick={() => {
                        setWorkModalState({...workModalState, modalOpen: true})
                      }}
                  >
                    Search Work Lookups
                  </Button>
                </Columns.Column>
              </Columns>
            </Box>
          </Box>
          <Box>
            <h1 className="title">Work Added After Quote</h1>
            <Columns>
              <Columns.Column>
                {quote.estimated_work_items
                    .filter(work => work.added_after_quote)
                    .map(value => (
                        <WorkSection
                            quote={quote.id}
                            key={value.id || value.key}
                            customer={quote.customer}
                            hireCustomer={quote.customer2}
                            vehicle={quote.vehicle}
                            index={value.index}
                            workItem={value}
                            workTypes={workTypes}
                            workTypeObjects={workTypeObjects}
                            update_work={updateWork}
                            remove_work_item={removeWorkItem}
                            onSearchPartClick={onSearchPartClick}
                            appDefaults={appDefaults}
                            additional={true}
                        />
                    ))}
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <Form.Field>
              <div className="control">
                <Button onClick={handleSave} fullwidth color="info">
                  Save Quote
                </Button>
              </div>
            </Form.Field>
            <Form.Field>
              <div className="control">
                <Button onClick={() => setImageModalOpen(true)} fullwidth color="info">
                  {"Show Attached Images ("+images.length+")"}
                </Button>
              </div>
            </Form.Field>
            <Form.Field>
              <ReactToPrint
                  trigger={() => (
                      <Button color="warning" fullwidth>
                        Print Pick List
                      </Button>
                  )}
                  content={() => printPickListRef.current}
                  bodyClass="print-landscape"
                  pageStyle="@page {
                  size: A4 landscape;
                }"
              />
            </Form.Field>
            {quote.id && (
                <Form.Field>
                  {/*<ReactToPrint*/}
                  {/*  trigger={() => (*/}
                  <Button
                      color="warning"
                      fullwidth
                      onClick={() => setPrintOptionsModalOpen(true)}
                  >
                    Print Workshop Quote Sheet
                  </Button>
                  {/*)}*/}
                  {/*content={() => componentRef.current}*/}
                  {/*bodyClass="print-landscape"*/}
                  {/*pageStyle=""*/}
                  {/*/>*/}
                </Form.Field>
            )}
            {quote.id && (
                <Form.Field>
                  <div className="control">
                    <Button
                        onClick={() =>
                            setCreateJobModalState({
                              ...createJobCardModalState,
                              modalOpen: true
                            })
                        }
                        fullwidth
                        color="success"
                    >
                      Create Job Card From Selected Items
                    </Button>
                  </div>
                </Form.Field>
            )}
            {quote.id && !quote.priced && (
                <Form.Field>
                  <div className="control">
                    <Button
                        onClick={() => {
                          triggerChange({priced: true})
                        }}
                        fullwidth
                        color="success"
                    >
                      Quote Priced
                    </Button>
                  </div>
                </Form.Field>
            )}
            {quote.id && (
                <Form.Field>
                  <div className="control">
                    <Button
                        onClick={() => {
                          setApproveModalOpen(true);
                        }}
                        fullwidth
                        color="success"
                    >
                      Approve for Invoicing
                    </Button>
                  </div>
                </Form.Field>
            )}
            {quote.id && (
                <Form.Field>
                  <Form.Control>
                    <Button
                        onClick={() => {
                          setDeleteModalOpen(true);
                        }}
                        fullwidth
                        color="danger"
                    >
                      Delete Quote
                    </Button>
                  </Form.Control>
                </Form.Field>
            )}
          </Box>
          <PartSearchModal
              {...partsModalState}
              setModalState={setPartsModalState}
              selectPart={searchSelectPart}
          />
          <WorkSearchModal
              {...workModalState}
              setModalState={setWorkModalState}
              selectWork={searchSelectWork}
          />
          <CreateJobCardModal
              {...createJobCardModalState}
              setModalState={setCreateJobModalState}
              workItems={quote.estimated_work_items.filter(item => !item.job)}
              quote={quote}
              endpoint={props.endpoint}
              history={props.history}
          />
          <NotReadyForInvoiceModal
              open={notReadyModalOpen}
              setOpen={setNotReadyModalOpen}
              items={notReadyItems}
          />
          <DeleteWarningModal
              open={deleteModalOpen}
              setOpen={setDeleteModalOpen}
              onDelete={handleDelete}
          />
          <PrintOptionsModal
              open={printOptionsModalOpen}
              setOpen={setPrintOptionsModalOpen}
              data={quote}
          />
          <ApprovalModal
              open={approveModalOpen}
              setOpen={setApproveModalOpen}
              job={quote}
              workItems={quote.estimated_work_items}
              onApprove={handleApproveForInvoice}
              isJob={false}
          />
          <FinalizeModal
              open={finalizeModalOpen}
              setOpen={setFinalizeModalOpen}
              quote={quote}
              onFinalize={handleFinalize}
          />
          <ImageModal
            job={quote}
            open={imageModalOpen}
            setOpen={setImageModalOpen}
            dataFresh={dataFresh}
            type={'quote'}
            images={images}
            imagesLoaded={imagesLoaded}
          />
          <div style={{display: "none"}}>
            {!quote.enquiry_description &&
            <div>
              <PrintPickList data={quote} ref={printPickListRef}/>
            </div>
            }
          </div>
        </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

export default QuoteForm;
