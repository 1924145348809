import React from "react";
import {Button, Columns, Heading, Modal, Section} from "react-bulma-components";


export default function InspectionCountModal({open, setOpen, count, onCancel}){

    return (
        <Modal
            show={open}
            onClose={() => {
                setOpen(false);
            }}
            closeOnBlur
        >
            <Modal.Content>
                <Section style={{background: "white"}}>
                    <Heading>THERE ARE {count} INSPECTIONS BOOKED ON THAT DATE</Heading>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                onClick={onCancel}
                                fullwidth
                            >
                                Close
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )



}