import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  createSetDataFreshAction,
  createSetInspectionTypesDataAction,
  createSetVehicleTypesDataAction
} from "../../../actions/dataActions";
import { getDataFresh, getInspectionTypesData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { useInspectionTypeData } from "../../../hooks/useInspectionTypeData";
import NewInspectionType from "./NewInspectionType";
import InspectionTypeRow from "./InspectionTypeRow";
import Collapsible from "react-collapsible";

function InspectionTypeView(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);

  const complete = useInspectionTypeData({ page }, dataFresh);

  const onInspectionTypeUpdate = (index, newInspectionType) => {
    let newState = data;
    newState.results[index] = newInspectionType;
    dispatch(createSetInspectionTypesDataAction(newState));
  };

  const data = useSelector(getInspectionTypesData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <NewInspectionType />
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Inspection Types"
        >
          <Table>
            <thead>
              <tr>
                <th>Inspection Type</th>
                <th>Reminder (days before expiry)</th>
                <th>Warning (days before expiry)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((value, index) => (
                <InspectionTypeRow
                  key={value.id}
                  inspectionTypeData={value}
                  index={index}
                  endpoint={props.endpoint}
                  onInspectionTypeUpdate={onInspectionTypeUpdate}
                />
              ))}
            </tbody>
          </Table>
          <Pagination
            showFirstLast={true}
            onChange={page => setPage(page)}
            current={page}
            total={Math.ceil(data.count / 25)}
          >
            {" "}
          </Pagination>
        </Collapsible>
      </Box>
    </div>
  );
}

export default InspectionTypeView;
