import React, { useState } from "react";

import InspectionRow from "./InspectionRow.js";

import {Box, Tabs} from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { createSetInspectionDataAction } from "../../actions/dataActions";
import {getDataFresh, getInspectionData, getInspectionTypesData} from "../../state/selectors";
import { useInspectionData } from "../../hooks/useInspectionData";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components"
import { Button } from "react-bulma-components";
import axios from "axios";
import { formatDate, readableDate } from "../../utils";
import SearchBar from "../Utils/SearchBar";
import CreateJobModal from "./CreateJobModal";
import {useInspectionTypeData} from "../../hooks/useInspectionTypeData";
import useSelect from "../../hooks/useSelect";
import InspectionCountModal from "./InspectionCountModal";

export default function Inspection(props) {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const [filters, setFilters] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [activeTab, setTab] = useState("all");
  const [activeView, setView] = useState("other");
  const [searchFresh, setSearchFresh] = useState(1);
  const [jobModalOpen, setJobModalOpen] = useState(false);
  const [jobData, setJobData] = useState({});
  const [inspectionCount, setInspectionCount] = useState(0);
  const [countModal, setCountModal] = useState(false);
  const [dayFilter, setDayFilter] = useState("all");

  const [showToday, setShowToday] = useState(false);
  const [showTomorrow, setShowTomorrow] = useState(false);
  const [show2Days, setShow2Days] = useState(false);
  let params = { page };

  if (currentSearch) {
    params.search = currentSearch;
  }
  if(dayFilter){
    params.day = dayFilter
  }

  if(showToday){
    params.showToday = showToday
  }
  if(showTomorrow){
    params.showTomorrow = showTomorrow
  }
  if(show2Days){
    params.show2Days = show2Days
  }
  if (activeView){
    params.type = activeView
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if(activeTab){
    params.view = activeTab;
  }
  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = useInspectionData(params, dataFresh);
  let data = useSelector(getInspectionData);

  const onInspectionUpdate = (index, newInspection) => {
    let newState = { ...data };
    newState.results[index] = newInspection;
    dispatch(createSetInspectionDataAction(newState));
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const getInspectionCount = (data, date) => {
    const conf = {
      url: props.endpoint+"inspectioncount?type="+data.inspection_type+"&date="+date,
      method: "get"
    }
    axios(conf).then(res => {
      setInspectionCount(res.data);
      setCountModal(true);
    })
  }


  const setNewTab = (tab) => {
    setPage(1);
    setTab(tab);
  };

  const setType = (e) => {
    setPage(1);
    setView(e.target.value)
  }
  const setNewView = (view) => {
    setDayFilter(view);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const headings = [
    {
      label: "Fleet Number"
    },
    {
      label: "Registration",
      field: "vehicle__registration",
      labelField: "vehicle__registration"
    },
    {
      label: "Vehicle Location",
      field: "vehicle__location",
      labelField: "vehicle__location"
    },
    {
      label: "Type",
      field: "inspection_type",
      labelField: "inspection_type"
    },
    {
      label: "Workshop",
      field: "inspection_workshop",
      labelField: "inspection_workshop__display_name"
    },
    {
      label: "Hire Customer",
      field: "vehicle__hire_customer",
      labelField: "vehicle__hire_customer__display_name"
    },
    {
      label: "Expiry Date",
      field: "expiry_date",
      labelField: "expiry_date",
      date: true
    },
    {
      label: "Last Completed",
      field: "date_completed",
      labelField: "date_completed",
      date: true
    },
    {
      label: "Comments"
    },
    {
      label: "Book In Date",
      field: "booked_in_date",
      labelField: "booked_in_date",
      date: true
    },
    {
      label: "New Completed Date",
      field: "new_completed_date",
      labelField: "new_completed_date",
      date: true
    },
    {
      label: "New Expiry Date",
      field: "new_expiry_date",
      labelField: "new_expiry_date",
      date: true
    },
    {
      label: "Vehicle Arrived"
    }
  ];

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Inspections";

  return (
    <div>
      <Box>
        <h1 className="title">Inspections</h1>
        <Columns>
          <Columns.Column size={4}>
            <Form.Label>Search</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              setSearchText={setSearchText}
              searchText={searchText}
              clear={clear}
            />
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <h1 className="title">Upcoming Inspections</h1>
          </Columns.Column>
        </Columns>
        <Columns/>
        <Columns>
          <Columns.Column>
            <Form.Label></Form.Label>
            <Tabs type="toggle" align="left">
              <Tabs.Tab active={activeTab === "all"} onClick={() => setNewTab("all")}>
                All
              </Tabs.Tab>
              <Tabs.Tab active={activeTab === "fleet"} onClick={() => setNewTab("fleet")}>
                Fleet
              </Tabs.Tab>
              <Tabs.Tab active={activeTab === "customer"} onClick={() => setNewTab("customer")}>
                Customer
              </Tabs.Tab>
            </Tabs>
          </Columns.Column>
          <Columns.Column>
            <Form.Label></Form.Label>
            <Tabs type="toggle" align="left">
              <Tabs.Tab active={dayFilter === "none"} onClick={() => setNewView("none")}>
                All
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "all"} onClick={() => setNewView("all")}>
                All Upcoming
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "2daysago"} onClick={() => setNewView("2daysago")}>
                2 Days Ago
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "yesterday"} onClick={() => setNewView("yesterday")}>
                Yesterday
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "today"} onClick={() => setNewView("today")}>
                Today
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "tomorrow"} onClick={() => setNewView("tomorrow")}>
                Tomorrow
              </Tabs.Tab>
              <Tabs.Tab active={dayFilter === "2days"} onClick={() => setNewView("2days")}>
                2 Days From Today
              </Tabs.Tab>
            </Tabs>
          </Columns.Column>
          <Columns.Column>
            <Form.Label>Inspection Type</Form.Label>
            <Form.Select
              onChange={setType}
              value={activeView}
            >
              <option key={"other"} value={"other"}>Other</option>
              <option key={"road"} value={"road"}>Road Tax</option>
            </Form.Select>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <table className="table is-hoverable is-fullwidth is-striped">
              <thead>
                <tr>
                  {headings.map(heading => {
                    if (!heading.field) {
                      return <th key={heading.label}>{heading.label}</th>;
                    }
                    if (heading.blank) {
                      return (
                        <FilterBlankHeading
                          key={heading.label}
                          heading={heading}
                          setFilters={f => {
                            setPage(1);
                            setFilters(f);
                          }}
                          filters={filters}
                        />
                      );
                    }
                    return (
                      <FilterHeading
                        key={heading.label}
                        heading={heading}
                        setFilters={f => {
                          setPage(1);
                          setFilters(f);
                        }}
                        filters={filters}
                        options={data.filter_list[heading.field]}
                      />
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.results.map((value, index) => (
                  <InspectionRow
                    key={value.id}
                    inspection={value}
                    index={index}
                    onInspectionUpdate={onInspectionUpdate}
                    endpoint={props.endpoint}
                    setJobModalOpen={setJobModalOpen}
                    setJobData={setJobData}
                    getCount={getInspectionCount}
                  />
                ))}
              </tbody>
            </table>
            <Pagination
              showFirstLast={true}
              onChange={page => setPage(page)}
              current={page}
              total={Math.ceil(data.count / 25)}
            />
            </Columns.Column>
        </Columns>
      </Box>
      {inspectionCount !== 0 &&
        <InspectionCountModal
          count={inspectionCount}
          open={countModal}
          setOpen={setCountModal}
          onCancel={() => setCountModal(false)}
        />
      }
      {jobData.inspection &&
      <CreateJobModal
        endpoint={props.endpoint}
        open={jobModalOpen}
        setOpen={setJobModalOpen}
        data={jobData}
        setData={setJobData}
      />
      }
    </div>
  );
}
