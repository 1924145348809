import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import React, {useRef, useState} from "react";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import useSafeSave from "../../hooks/useSafeSave";
import { useDispatch, useSelector } from "react-redux";
import {getDataFresh} from "../../state/selectors";
import PrintJob from "./PrintJob";
import ReactToPrint from "react-to-print";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import FitterSelector from "./FitterSelector";
import {getStatus, fixEmptyDates, readableDate, formatDate} from "../../utils";
import FAIcon from "../Icon/FAIcon";
import NotificationManager from "react-notifications/lib/NotificationManager";

function JobRow({ job, endpoint, onJobUpdate, index, showApprover, fitters, showWash, showStatusChange }) {
  const componentRef = useRef();
  const dateFields = ["book_in_date", "workshop_date", "finish_date"];
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const {status, approved} = getStatus(job, "Job");

  const submitUpdate = () => {
    const url = endpoint + "jobs/" + job.id;
    const data = fixEmptyDates(job, dateFields);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onSave = e => {
    return submitUpdate();
  };

  const checkUpdated = () => {
    return axios.get(endpoint + "jobs/" + job.id).then(newJob => {
      return newJob.data.last_saved === job.last_saved;
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newJob = { ...job };
    newJob[e.target.name] = e.target.value;
    onJobUpdate(index, newJob);
  };

  const onCheck = e => {
    onChange({target: {name: e.target.name, value: !job[e.target.name]}});
  };

  const statusChange = (status) => {
    let newJob = job;
    const url = endpoint + "jobs/returned/" + job.id;
    newJob.status = status;

    axios.put(url, newJob).then(response => {
      dispatch(createSetDataFreshAction(dataFresh + 1))
    }).catch(reason => {
      NotificationManager.error("An error occurred!")
    });
  }


  const triggerFitterChange = data => {
    makeDirty();
    setLastEdited(new Date());
    let newJob = {...job};
    newJob['fitter'] = data.fitter;
    onJobUpdate(index, newJob);
  };
  return (
    <tr
      className="clickable small-row-black"
      onClick={() => window.open(`/editjob/${job.id}`, "_self")}
      key={job.id}
    >
      <td>{job.id}</td>
      <td>{job.is_enquiry ? "" : job.vehicle.registration}</td>
      <td>{job.is_defect ? job.defect_description : job.is_enquiry ? job.enquiry_description : job.job_description}</td>
      <td>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Textarea
            rows={2}
            className="small-row-black min-column-width"
            type="text"
            value={job.comments_list.length > 0 ? readableDate(formatDate(new Date(job.comments_list[job.comments_list.length -1].created))) +"\n"+ job.comments_list[job.comments_list.length -1].text : ""}
            disabled
            readOnly
          />
        </Form.Control>
      </Form.Field>
      </td>
      <td>{job.customer ? job.customer.display_name : ""}</td>
      <td>{job.customer2 ? job.customer2.display_name : ""}</td>
      <td onClick={e => e.stopPropagation()}>
        <FitterSelector
          fitters={fitters}
          value={job.fitter ? job.fitter.id  : ""}
          triggerChange={triggerFitterChange}
          editable={editable}
          setEditable={setEditable}
          onSave={safeSave}
          inlineEdit={!job.is_defect || !job.is_enquiry}
        />
      </td>
      <td>{job.is_enquiry ? "" : job.vehicle.make ? job.vehicle.make.name : ""}</td>
      <td>{job.is_enquiry ? "" :job.vehicle.model ? job.vehicle.model.name : ""}</td>
      <td data-testid={"job-status"+index}>{status}</td>
      <td onClick={e => e.stopPropagation()}>
        <Form.Select
          value={job.vehicle_arrived}
          name={"vehicle_arrived"}
          onChange={onChange}
          disabled={!editable}
          data-testid={"vehicle-arrived"+index}
        >
          <option key={"false"} value={"false"}>No</option>
          <option key={"true"} value={"true"}>Yes</option>
        </Form.Select>
      </td>
      <td onClick={e => e.stopPropagation()}>
        <Form.Select
          value={job.wash_needed}
          name={"wash_needed"}
          onChange={onChange}
          disabled={!editable}
          data-testid={"wash-needed"+index}
        >
          <option key={"false"} value={"false"}>No</option>
          <option key={"true"} value={"true"}>Yes</option>
        </Form.Select>
      </td>
      {useEditableTableDateCell({
        propName: "workshop_date",
        value: job.workshop_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: !job.is_defect || !job.is_enquiry
      })}
      {useEditableTableDateCell({
        propName: "book_in_date",
        value: job.book_in_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: !job.is_defect || !job.is_enquiry
      })}
      <td>{job.workshop ? job.workshop.display_name : ""}</td>
      {showApprover &&
        <td>{job.approving_user ? `${job.approving_user.first_name} ${job.approving_user.last_name}` : ""}</td>
      }
      {showWash &&
      <td onClick={e => e.stopPropagation()}>
        <Form.Control className={"has-addons"}>
          <Form.Checkbox
            name={"wash_complete"}
            onChange={onCheck}
            checked={job.wash_complete}
            disabled={!editable}
          />
          {editable ? (
                <Form.Control>
                    <Button className={"small-row-white"} color="success" onClick={onSave}>
                        <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                    </Button>
                </Form.Control>
            ) : (
                <Form.Control>
                    <Button
                        onClick={e => {
                            e.stopPropagation()
                            setEditable(!editable)}
                        }
                        className={"small-row-black"}
                    >
                        <FAIcon size="small" icon={["fas", "edit"]}/>
                    </Button>
                </Form.Control>
            )}
        </Form.Control>
      </td>
      }
      {showStatusChange && (
        <td onClick={e => e.stopPropagation()}>
          <Button
            onClick={() => statusChange("COLLECTED")}
            color={"success"}
          >
            COLLECTED
          </Button>
        </td>
      )
      }
      <td onClick={e => e.stopPropagation()}>
        <ReactToPrint
          trigger={() => (
            <Button
                color="warning"
                disabled={job.is_defect || job.is_enquiry}
            >
              Print
            </Button>
          )}
          content={() => componentRef.current}
          bodyClass="print-landscape"
          pageStyle=""
        />
      </td>
      <div style={{ display: "none" }}>
        <div>
          <PrintJob data={job} ref={componentRef}></PrintJob>
        </div>
      </div>
    </tr>
  );
}

export default JobRow;
