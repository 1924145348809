import React, {useState} from "react";
import {readableDate} from "../../../../utils";
import useEditableTableTextCell from "../../../../hooks/useEditableTableTextCell";
import useEditableTableTextAreaCell from "../../../../hooks/useEditableTableTextAreaCell";

function ForSaleRow({value, finance, activeTab, onChange, onSave, index}) {
    const [editable, setEditable] = useState(false);
  return (
      <tr
          className="clickable"
          onClick={() => window.open("/editvehicle/" + value.id, "_self")}
          key={value.id}
      >
          <td data-testid={"vehicle-registration"}>
    <span className="registration-field">
    {value.registration}
    </span>
          </td>
          <td data-testid={"vehicle-make"}>{value.make ? value.make.name : ""}</td>
          <td data-testid={"vehicle-model"}>{value.model ? value.model.name : ""}</td>
          <td data-testid={"vehicle-variant"}>{value.variant}</td>
          <td data-testid={"vehicle-type"}>{value.type ? value.type.name : ""}</td>
          {
              useEditableTableTextCell({
                  propName: "mileage",
                  value: value.mileage ?? 0,
                  onChange: (e) => onChange(e, index, value),
                  onSave: () => {
                      onSave(value);
                      setEditable(false);
                  },
                  editable,
                  setEditable,
                  inlineEdit: true
              })
          }
          <td data-testid={"vehicle-salecomments"}>{finance.sale_comments}</td>
          <td data-testid={"vehicle-remainingbal"}>
              £
              {!finance.finance_early_settlement_date && finance.remaining_balance
                  ? parseFloat(finance.remaining_balance - finance.hp_remaining_interest).toFixed(2)
                  : 0.00
              }
          </td>
          <td data-testid={"vehicle-estimatedvalue"}>
              £
              {typeof finance.estimated_value === "string"
                  ? finance.estimated_value
                  : (finance.estimated_value || 0).toFixed(2)}
          </td>
          <td data-testid={"cap-retail"}>£{value.cap_retail ?? 0}</td>
          <td data-testid={"cap-clean"}>£{value.cap_clean ?? 0}</td>
          <td data-testid={"cap-average"}>£{value.cap_average ?? 0}</td>
          <td data-testid={"cap-below"}>£{value.cap_below ?? 0}</td>
          <td data-testid={"vehicle-advertised"}>{finance.advertised ? "Yes" : "No"}</td>
          <td data-testid={"vehicle-listprice"}>£{finance.external_list_price ? parseFloat(finance.external_list_price).toFixed(2)
              : 0.00}</td>
      </tr>
  )
}

export default ForSaleRow;
