import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Link } from "react-router-dom";
import { formatCurrency, getWorkTotals } from "../../utils";
import WorkTotals from "../WorkTotals/WorkTotals";

export default function ApprovalModal({
  open,
  setOpen,
  job,
  workItems,
  onApprove,
  isJob,
  handleChange
}) {
  const onClick = () => {
    onApprove();
    setOpen(false);
  };

  const handleInspectionChange = e => {
    let newInspection = {...job.inspection, [e.target.name]: e.target.value};
    const toUpdate = {
      target: {
        name: "inspection",
        value: newInspection
      }
    };
    handleChange(toUpdate);
  }

  const vehicle = job.vehicle ? job.vehicle : {};

  let supplierTotals = {};
  let labourCosts = 0;

  workItems.forEach(item => {
    labourCosts += item.labour_cost;
    item.parts
      .filter(part => part.supplier)
      .forEach(part => {
        if (!supplierTotals[part.supplier.display_name]) {
          supplierTotals[part.supplier.display_name] = 1 * part.cost;
        } else {
          supplierTotals[part.supplier.display_name] += 1 * part.cost;
        }
      });
  });

  const customer_label = job.customer
    ? ` - (${job.customer.display_name})`
    : "";
  const customer2_label = job.customer2
    ? ` - (${job.customer2.display_name})`
    : "";

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Are you sure you want to approve?
          </Heading>
          <Columns>
            <Columns.Column>
              {(isJob && !job.mileage) &&
              <p style={{color: "red", fontWeight: "bold"}}>You cannot approve this job until you specify a mileage!</p>
              }
              {(isJob && job.inspection) && (!job.inspection.new_expiry_date || !job.inspection.new_date_completed) &&
                  <p style={{color: "red", fontWeight: "bold"}}>You cannot approve this job until new inspection dates are specified! </p>
              }
              <p className="is-centered">
              Verify the following details before confirming:
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label className="label">Registration</Form.Label>
                <Form.Control className="registration-wrapper">
                  <Form.Input
                    className="registration-field"
                    type="text"
                    name="registration"
                    value={vehicle.registration}
                    disabled
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Make</Form.Label>
                  <Form.Input
                    type="text"
                    name="make"
                    value={vehicle.make ? vehicle.make.name : ""}
                    readOnly
                    disabled
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Model</Form.Label>
                <Form.Input
                  type="text"
                  name="model"
                  value={vehicle.model ? vehicle.model.name : ""}
                  readOnly
                  disabled
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            {job.workshop && (
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Workshop</Form.Label>
                  <Form.Input
                    type="text"
                    name="workshop"
                    value={job.workshop ? job.workshop.display_name : ""}
                    readOnly
                    disabled
                  />
                </Form.Field>
              </Columns.Column>
            )}
            <Columns.Column>
              <Form.Field>
                <Form.Label>Total Labour Cost</Form.Label>
                <Form.Input
                  type="text"
                  name="labour"
                  value={formatCurrency(labourCosts)}
                  readOnly
                  disabled
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                  <Form.Label>Department</Form.Label>
                  <Form.Input
                    type="text"
                    name="department"
                    value={job.department ? job.department.name : ""}
                    readOnly
                    disabled
                  />
                </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Label>Supplier Parts Breakdown</Form.Label>
              <Table>
                <thead>
                  <th>Supplier</th>
                  <th>Parts Cost</th>
                </thead>
                <tbody>
                  {Object.keys(supplierTotals).map((supplier, index) => (
                    <tr key={index}>
                      <td>{supplier}</td>
                      <td>{formatCurrency(supplierTotals[supplier])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Box>
                <Heading size={6}>Customer Total{customer_label}</Heading>
                <WorkTotals
                  workItems={workItems}
                  allItems={false}
                  chargeable={false}
                  labourFieldName="customer_labour_cost"
                  partFieldName="customer_cost"
                />
              </Box>
            </Columns.Column>
            <Columns.Column>
              <Box>
                <Heading size={6}>
                  Customer 2 Total{customer2_label}
                </Heading>
                <WorkTotals
                  workItems={workItems}
                  allItems={false}
                  chargeable={true}
                  labourFieldName="customer_labour_cost"
                  partFieldName="customer_cost"
                />
              </Box>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Customer Invoice Number</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="customer_invoice_number"
                    onChange={handleChange}
                    value={job.customer_invoice_number}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Customer 2 Invoice Number</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="customer2_invoice_number"
                    onChange={handleChange}
                    value={job.customer2_invoice_number}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Workshop Invoice Number</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    name="workshop_invoice_number"
                    onChange={handleChange}
                    value={job.workshop_invoice_number}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          {isJob && job.inspection &&
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>New Completed Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="new_completed_date"
                      onChange={handleInspectionChange}
                      value={job.inspection.new_completed_date}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>New Expiry Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="new_expiry_date"
                      onChange={handleInspectionChange}
                      value={job.inspection.new_expiry_date}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          }
          <Columns>
            <Columns.Column>
              <Button color="info" fullwidth onClick={onClick}>
                Approve
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
