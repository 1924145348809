import React from "react";
import {Button} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch} from "react-redux";



export default function PartRow({workLookup, endpoint, dataFresh, index, disabled}){
    const dispatch = useDispatch();
    const part = workLookup.part_work_lookup[index]


    const handleDelete = e => {

        if(part.id){
            // Remove the work lookup from the part lookup
            part['work_lookup'].forEach((work, index) => {
                if (work === workLookup.id) {
                    part['work_lookup'].splice(index, 1);
                }
            });
            const url = endpoint + "partlookup/update/" + part.id;
            const conf = {
                method: "put",
                url,
                data: part
            };

            return axios(conf).then(() => {
              dispatch(createSetDataFreshAction(dataFresh + 1));
            });
        }
        else{
            console.log(workLookup);
            workLookup.parts = workLookup.part_work_lookup.slice(0,index).concat(workLookup.part_work_lookup.slice(index+1));
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }

    }



    return (
        <tr>
            <td data-testid={"part-name"+index}>{part.name}</td>
            <td data-testid={"part-number"+index}>{part.part_number}</td>
            <td data-testid={"supplier-name"+index}>{part.supplier ? part.supplier.display_name : ""}</td>
            <td data-testid={"part-cost"+index}>£{part.cost}</td>
            <td>
                {!disabled &&
                    <Button
                        color={"danger"}
                        onClick={handleDelete}
                    >
                        Remove
                    </Button>
                }
            </td>
        </tr>
    )
}