import React from "react";
import {Box, Columns, Table} from "react-bulma-components";
import NewWorkType from "./NewWorkType";
import Collapsible from "react-collapsible";
import {useDispatch, useSelector} from "react-redux";
import {useWorkTypeData} from "../../../hooks/useWorkTypeData";
import {getDataFresh, getWorkTypeData} from "../../../state/selectors";
import Spinner from "react-spinner-material";
import WorkTypeRow from "./WorkTypeRow";
import axios from "axios";
import {
    createSetDataFreshAction,
    createSetWorkTypeDataAction
} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";


export default function WorkTypeView(props){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    let loading = useWorkTypeData({}, dataFresh);
    let data = useSelector(getWorkTypeData);



    const onWorkTypeUpdate = (index, newWorkType) => {
        let newState = data;
        newState.results[index] = newWorkType;
        dispatch(createSetWorkTypeDataAction(newState));
    }

    if(!loading){
        return(
            <Box>
                <Spinner
                  className="spinner-centre"
                  size={120}
                  spinnerColor={"#3273dc"}
                  spinnerWidth={2}
                />
            </Box>
        );
    }


    return (
        <div>
            <NewWorkType/>
            <Box>
                <Collapsible
                    trigger={"Workshop Expense Charge Types"}
                    className={"title"}
                    triggerOpenedClassName={"title"}
                >
                    <Columns>
                        <Columns.Column>
                            <p className={"bold"}>Note:</p>
                            <ul>
                                <li>Free of charge allows work items to skip workshop expenses (they still go to customer invoicing)</li>
                                <li>Approval Required forces an extra approval step (approval received) on work items before they can be approved on workshop expenses</li>
                            </ul>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Table
                                size={"fullwidth"}
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Free of Charge Work</th>
                                        <th>Approval Needed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.results.map((workType, index) => (
                                        <WorkTypeRow
                                            key={workType.id}
                                            workTypeData={workType}
                                            index={index}
                                            dataFresh={dataFresh}
                                            onUpdate={onWorkTypeUpdate}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )



}